.Nav {
    display: flex;
}

.NavOpen {
    position: absolute;
    z-index: 100;

    border-radius: 0.5rem;
    box-shadow: 1px 2px 5px #111;
}

.NavOpen .Menu {
    display: flex;
    flex-direction: column;
}

.NavOpen .NavBtn {
    border: 0;
    box-shadow: none;
    width: 100%;
}

.NavOpen .NavActive {
    display: block;
    background-color: #23272e;
    border-top: 1px solid #2D3239;
    border-left: 1px solid #2D3239;
    border-bottom: 1px solid #2D3239;
}

.NavOpen .NavActiveDesc {
    display: block;
}

.NavOpen .NavBtnArrow {
    display: none;
}

.NavActive {
    max-width: 20rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.NavActiveDesc {
    display: none;
    padding: 0 1rem;
    color: #ccc;
}

.NavBtn {
    padding: 0;
    background-color: #23272e;

    border: 1px solid #2D3239;
    border-radius: 0.5rem;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    font-family: inherit;

    box-shadow: 1px 2px 5px #111;

    cursor: pointer;

    /*box-shadow: 3px 3px 5px rgba(9, 9, 12, 0.25) inset;*/
    /*font-size: 1rem;*/
}

.NavBtnIcon img {
    max-width: 3rem;
    height: auto;
}

.NavBtnText {
    color: #fff;
    font-size: 1rem;
}

.NavBtnArrow {
    width: 2rem;
    fill: #6f7d8c;
    margin: 0 0.25rem;
}

.Menu {
    display: none;
    /*position: absolute;*/
    /*border-top: 0.25rem solid transparent;*/
    /*width: 100%;*/
}

.MenuItem {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    cursor: pointer;
    background-color: #2D3239;
    border-right: 1px solid #2D3239;
    text-decoration: none;
}

.MenuItem:first-child {
    border-top: 1px solid #2D3239;
    border-top-right-radius: 0.5rem;
}

.MenuItem:last-child {
    border-bottom: 1px solid #2D3239;
    border-bottom-right-radius: 0.5rem;
}

.MenuItemActive {
    background-color: #23272e;
    cursor: default;
}

.MenuItem:not(.MenuItemActive):hover {
    background-color: #23272e;
}

.MenuItemIcon img {
    width: 3rem;
    height: auto;
}

.MenuItemText {
    padding: 1rem 1rem 1rem 0;
    color: #f9a620;
}

.Block {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem 1rem;
}

.Fieldset {
    margin: 0;
}

@media screen and (min-width: 1200px) {
    .Block {
        padding: 2rem;
    }
}

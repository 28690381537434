.MobileMenu {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.MobileMenuLink {
    padding: 1rem 0;
    display: block;
    font-size: 1.25rem;
    text-align: center;
    width: 100%;
    color: orange;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
}

.MobileMenuLink:hover {
    background-color: #2c313a;
    color: #cc8500;
}

.MobileMenuAccount {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #23272e;
    margin-top: 2rem;
}

/* block */
.Progress {
    display: flex;
    /*gap: 1rem;*/
    /*position: sticky;*/
    /*bottom: 0; !* fix gap in chrome *!*/
    /*z-index: 1000;*/
    /*box-shadow: 0 -3px 3px rgba(17, 17, 17, 0.125);*/
    /*width: 100%;*/
}

@media screen and (min-width: 1200px) {
    .Progress {
    }
}

/* elements */
.ProgressBar {
    box-sizing: content-box;
    height: 1.25rem;
    position: relative;
    background: #23272e;
    border-radius: 25px;
    padding: 0.5rem;
    /*box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.25);*/
    flex: 1;
    align-items: center;
    border: 1px solid #303640;
    text-align: center;
    width: 100%;
    margin-right: 1rem;
}

.ProgressBarFill {
    display: block;
    height: 100%;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    background-color: #3E885B;
    /*background-image: linear-gradient(*/
    /*        center bottom,*/
    /*        #3E885B 37%,*/
    /*        #499f6d 69%*/
    /*);*/
    /*box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),*/
    /*inset 0 -2px 6px rgba(0, 0, 0, 0.4);*/
    position: relative;
    overflow: hidden;
    transition: width 500ms;
}

.ProgressTicker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 6rem;
}

.ProgressLabel {
    font-size: 0.875rem;
    color: #ccc;
}

.ProgressTickerText {
    font-size: 0.875rem;
    color: orange;
}

/* modifiers */

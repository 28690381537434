.Account {

}

.AccountText {
    color: #ccc;
    font-size: 0.875rem;
    margin-bottom: 1rem;
}

.AccountLoginText {
    padding: 0;
    border: none;
    background-color: transparent;
    color: orange;
    cursor: pointer;
}

.AccountLoginText:hover {
    color: #cc8500;
}

.AccountIcons > button:not(:last-child) {
    margin-right: 1rem;
}

.AccountRow {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.AccountRow .AccountText {
    text-align: right;
    margin-right: 1rem;
    margin-bottom: 0;
}

@media screen and (min-width: 1200px) {

}

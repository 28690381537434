.Btn {
    position: relative;
    border: 1px solid #39404d;
    padding: 0.5rem;
    border-radius: 0.5rem;
    max-width: 5rem;
    background-color: #303640;
    cursor: pointer;
    top: -1px;
    box-shadow: 1px 2px 5px #111;
}

.BtnMobile {
    display: inline-block;
}

.BtnDesktop {
    display: none;
}

@media screen and (min-width: 1200px) {
    .BtnMobile {
        display: none;
    }

    .BtnDesktop {
        display: inline-block;
    }
}

.Btn svg {
    width: 2rem;
    height: 2rem;
}

.Btn:active,
.BtnPressed {
    top: 0;
    box-shadow: none;
}

.BtnDark {
    border: 1px solid #2D3239;
    background-color: #23272e;
    fill: #6F7D8C;
}

.BtnGrey svg {
    fill: #6F7D8C;
}

.BtnGrey:hover svg,
.BtnGrey:active svg {
    fill: #8394a6;
}

.BtnDiscord svg {
    fill: #7289da;
}

.BtnDiscord:hover svg,
.BtnDiscord:active svg {
    fill: #7e97f2;
}

.BtnBattleNet svg {
    fill: #009AE4;
}

.BtnBattleNet:hover svg,
.BtnBattleNet:active svg {
    fill: #00adfc;
}

.BtnGreen svg {
    fill: #3e885b;
}

.BtnGreen:hover svg,
.BtnGreen:active svg {
    fill: #4aa16d;
}
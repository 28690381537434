.Button {
    position: absolute;
    display: block;
    top: 1.5rem;
    right: 1.5rem;

    font-size: 1rem;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0;

    cursor: pointer;
}

.Button:active svg {
    position: relative;
    top: 1px;
}

.Button svg {
    width: 2rem;
    height: 2rem;
    fill: #ccc;
}

.Button:hover svg {
    fill: #e6e6e6;
}

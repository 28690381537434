.Block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #282c34;
    color: white;
}

.Header {
    position: sticky;
    top: 0;
    z-index: 5000;
    height: 6rem;
    background-color: #1e2126;
}

.StickyTop {
    position: sticky;
    top: 0;
    z-index: 5000;
    background-color: #1e2126;
}

.Content {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.Sidebar {
    display: none;
}

.Main {
    position: relative;
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
}

.StickyBottom {
    position: sticky;
    bottom: 0;
    background-color: #1e2126;
    padding: 1rem;
    z-index: 5000;
}

.Footer {
    display: block;
    background-color: #1e2126;
    border-top: 1px solid #282c34;
}

@media screen and (min-width: 1200px) {
    .Block {
        height: 100vh;
        justify-content: flex-start;
    }

    .Header {
        box-shadow: 0 1px 3px rgba(17, 17, 17, 0.5);
        border-bottom: 1px solid #303640;
    }

    .StickyTop {
        display: none;
    }

    .Content {
        overflow: auto;
        flex-direction: row;
    }

    .Sidebar {
        position: sticky;
        top: 0;

        display: flex;
        flex-direction: column;
        width: 50rem;
        height: calc(100vh - 6rem);
        overflow-y: auto;

        background-color: #252930;
        border-right: 1px solid #1d2026;
        border-bottom: none;

        z-index: 10;
    }

    .Main {
        display: block;
        flex: auto;
    }

    .StickyBottom {
        display: none;
    }

    .Footer {
        display: none;
    }
}
